import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import { loginRequest } from "../../axiosRoutes/index";
import {
  Button,
  OutlinedInput,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
// import bg from "../../images/adminBG.png";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!values.email) {
      toast.error("Please Enter Email", toastConfig);
      return;
    } else if (!values.password) {
      toast.error("Please Enter Password", toastConfig);
      return;
    }

    try {
      const response = await loginRequest("auth/login", values);
      const { _id, role } = response.user;
      const { access, expires, refresh } = response.token;
      if (role === "admin") {
        sessionStorage.setItem("access", access);
        sessionStorage.setItem("expires", expires);
        sessionStorage.setItem("refresh", refresh);
        sessionStorage.setItem("role", role);
        setValues({
          email: "",
          password: "",
        });
        toast.success("Welcome! You are logged in.", toastConfig);
        navigate("/");
      } else if (role === "employee") {
        sessionStorage.setItem("access", access);
        sessionStorage.setItem("expires", expires);
        sessionStorage.setItem("refresh", refresh);
        sessionStorage.setItem("role", role);
        sessionStorage.setItem("userId", _id);
        setValues({
          email: "",
          password: "",
        });
        toast.success("Welcome! You are logged in.", toastConfig);
        navigate("/profile");
      } else {
        toast.error("Access Denied!", toastConfig);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data.message}`, toastConfig);
      } else if (error.request) {
        toast.error(
          "No response from server. Please try again later.",
          toastConfig
        );
      } else {
        toast.error(`Error: ${error.message}`, toastConfig);
      }
    }
  };

  useEffect(() => {
    const access = sessionStorage.getItem("access");
    const expires = sessionStorage.getItem("expires");
    const refresh = sessionStorage.getItem("refresh");
    if (!access && !expires && !refresh) {
      navigate("/login");
    } else if (access && expires && refresh) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Grid container>
      <ToastContainer />
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        lg={4}
        xl={3}
        sx={{
          bgcolor: "#1C1D21",
          height: "100vh",
          color: "#FEFEFE",
          padding: 4,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <img
              src={logo}
              alt="logo"
              style={{ width: 280, marginBottom: "2rem" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Login
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Enter your account details</Typography>
          </Grid>
          <Grid item sx={{ width: "100%", maxWidth: 300 }}>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  color: "#FEFEFE",
                  "& fieldset": { borderColor: "#FEFEFE" },
                  "&:hover fieldset": { borderColor: "#FEFEFE" },
                  "&.Mui-focused fieldset": { borderColor: "#FEFEFE" },
                },
                "& .MuiInputLabel-root": { color: "#FEFEFE" },
                "& .MuiIconButton-root": { color: "#FEFEFE" },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-email">
                Email Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleInput}
                label="Email Address"
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  color: "#FEFEFE",
                  "& fieldset": { borderColor: "#FEFEFE" },
                  "&:hover fieldset": { borderColor: "#FEFEFE" },
                  "&.Mui-focused fieldset": { borderColor: "#FEFEFE" },
                },
                "& .MuiInputLabel-root": { color: "#FEFEFE" },
                "& .MuiIconButton-root": { color: "#FEFEFE" },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
            <Typography
              variant="body2"
              sx={{ alignSelf: "center", mb: 2, cursor: "pointer" }}
            >
              Forgot Password?
            </Typography>
            <Button
              variant="contained"
              onClick={handleLogin}
              fullWidth
              sx={{ bgcolor: "#E5622D", "&:hover": { bgcolor: "#D0521B" } }}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={false} sm={6} md={7} lg={8} xl={9}>
      <div className="invisible md:visible w-3/5 bg-neutral-800">
        <div className="relative grid grid-cols-3 w-full">
          <div className="grid grid-cols-3 col-span-3">
            <svg
              className="col-span-1 ml-96 -mr-30"
              width="200"
              height="200"
              viewBox="0 0 297 222"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.3073 -22.9419C88.2219 -45.5972 123.623 -50.6075 158.797 -51.2769C190.998 -51.8897 224.821 -48.4654 248.377 -26.5019C271.085 -5.32876 270.217 28.1531 276.577 58.5422C284.477 96.2888 308.691 135.756 289.316 169.101C268.574 204.799 223.355 219.04 182.12 221.108C142.251 223.108 106.28 204.102 74.5541 179.875C40.7509 154.062 2.99212 124.646 0.220078 82.2046C-2.5263 40.1558 29.0695 4.19407 61.3073 -22.9419Z"
                fill="#FEFEFE"
              />
            </svg>
            <svg
              className="col-span-1 ml-72"
              width="250"
              height="250"
              viewBox="0 0 383 341"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M246.183 -105.329C312.639 -117.256 371.52 -63.0773 418.718 -14.7965C465.269 32.8225 509.63 91.4428 503.349 157.738C497.371 220.846 441.486 265.079 388.857 300.412C346.913 328.57 296.568 326.713 246.183 330.386C189.06 334.551 127.863 355.88 81.0708 322.851C29.1946 286.233 -8.32216 220.457 1.5951 157.738C10.9568 98.5328 85.3425 81.6115 126.157 37.7128C170.216 -9.67467 182.496 -93.898 246.183 -105.329Z"
                fill="#1C1D21"
              />
            </svg>
            <svg
              className="col-span-1 ml-28 mt-32"
              width="158"
              height="158"
              viewBox="0 0 200 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M105.811 4.5008C134.858 5.34946 166.738 -2.11863 188.758 16.8444C211.719 36.6192 219.208 69.6126 216.458 99.7907C213.923 127.614 195.618 150.135 174.904 168.883C155.442 186.498 132.001 198.163 105.811 199.94C77.5003 201.86 47.1208 198.603 26.6614 178.941C5.91888 159.006 3.1149 128.52 1.6037 99.7907C-0.0241652 68.8436 -4.99758 32.7644 17.8723 11.8517C40.439 -8.78384 75.2452 3.60776 105.811 4.5008Z"
                fill="#E5622D"
              />
            </svg>
          </div>
          <div className="grid grid-cols-3 col-span-3">
            <svg
              className="-mt-56 -ml-10"
              width="250"
              height="389"
              viewBox="0 0 422 389"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M205.374 8.7358C261.752 10.383 323.63 -4.11215 366.368 32.694C410.935 71.0758 425.47 135.114 420.133 193.688C415.212 247.692 379.683 291.403 339.479 327.793C301.705 361.983 256.206 384.623 205.374 388.072C150.424 391.8 91.4587 385.477 51.7483 347.313C11.4882 308.621 6.04585 249.45 3.1127 193.688C-0.0469032 133.622 -9.70002 63.5939 34.6891 23.0034C78.4897 -17.0489 146.047 7.00245 205.374 8.7358Z"
                fill="#1C1D21"
              />
            </svg>
            <svg
              className="-mt-8 ml-36"
              width="400"
              height="80"
              viewBox="0 0 400 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8C0 3.58172 3.58172 0 8 0H262C266.418 0 270 3.58172 270 8V71C270 75.4183 266.418 79 262 79H8C3.58173 79 0 75.4183 0 71V8Z"
                fill="white"
                fillOpacity="0.7"
              />
            </svg>
          </div>

          <div className="grid grid-cols-3 col-span-3">
            <svg
              className="col-span-3 -mt-2 ml-40"
              width="200"
              height="200"
              viewBox="0 0 297 274"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.3073 29.0586C88.2219 6.40331 123.623 1.39297 158.797 0.723607C190.998 0.110826 224.821 3.53503 248.377 25.4986C271.085 46.6717 270.217 80.1536 276.577 110.543C284.477 148.289 308.691 187.757 289.316 221.101C268.574 256.8 223.355 271.04 182.12 273.109C142.251 275.109 106.28 256.102 74.5541 231.875C40.7509 206.062 2.99212 176.647 0.22008 134.205C-2.5263 92.1563 29.0695 56.1946 61.3073 29.0586Z"
                fill="#E5622D"
              />
            </svg>
          </div>
          <div className="absolute font-bold text-8xl pt-40 -top-10">
            <h1 className="text-white ml-32 pt-3">Welcome to</h1>
            <h1 className="text-orange-600 ml-32 pt-3">Burnt Brains</h1>
            <img className="size-80 -mt-3 ml-56" src={bg} alt="bgImage" />
          </div>
        </div>
      </div>
      </Grid> */}
    </Grid>
  );
}

export default Login;
