import React, { useState, useEffect } from "react";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../../axiosRoutes/toastConfig";

const AddFitness = ({ open, handleClose, fitnessId }) => {
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [fitnessData, setFitnessData] = useState({
    userId: "",
    date: "",
    status: "",
    totalAmount: "",
  });

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/status");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setStatuses(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  useEffect(() => {
    const fetchFitness = async () => {
      if (fitnessId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const response = await getRequest(
            `admin/fitnessAllowance/${fitnessId}`,
            {
              headers: {
                Authorization: `Bearer ${access}`,
              },
            }
          );
          const formatDate = new Date(response.date)
            .toISOString()
            .split("T")[0];
          setFitnessData({
            userId: response.userId,
            date: formatDate,
            status: response.status,
            totalAmount: response.totalAmount,
          });
        } catch (error) {
          console.error("Error fetching Fitness Allowances:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setFitnessData({
          userId: "",
          date: "",
          status: "",
          totalAmount: "",
        });
      }
    };
    fetchFitness();
  }, [fitnessId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `admin/fitnessAllowance/${fitnessId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        fitnessData
      );
      toast.success("Fitness Updated Successfully", toastConfig);
      setFitnessData({
        userId: "",
        date: "",
        status: "",
        totalAmount: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest("employee", {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        setEmployees(response);
      } catch (error) {
        console.error("Error fetching Employees:", error);
      }
    };
    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFitnessData({ ...fitnessData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "admin/fitnessAllowance",
        {
          Authorization: `Bearer ${access}`,
        },
        fitnessData
      );
      toast.success("Fitness Added Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {fitnessId ? "Update Fitness Allowance" : "Add Fitness Allowance"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel id="employee-select-label">Employee</InputLabel>
              <Select
                labelId="employee-select-label"
                name="userId"
                readOnly={fitnessId ? true : false}
                value={fitnessData.userId}
                onChange={handleChange}
                label="Employee"
              >
                {employees.map((employee) => (
                  <MenuItem
                    key={employee.userId._id}
                    value={employee.userId._id}
                  >
                    {employee.userId.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel
                htmlFor="outlined-adornment-date"
                shrink
                sx={{
                  backgroundColor: "#000",
                  paddingX: "4px",
                  marginLeft: "-4px",
                }}
              >
                Date
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-date"
                type="date"
                name="date"
                label="Date"
                value={fitnessData.date}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-totalAmount">
                Total Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-totalAmount"
                type="text"
                name="totalAmount"
                label="Total Amount"
                value={fitnessData.totalAmount}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-status">
                Status
              </InputLabel>
              <Select
                labelId="outlined-adornment-status-label"
                id="outlined-adornment-status"
                name="status"
                label="Status"
                value={fitnessData.status}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              >
                {statuses.map((statusOpt) => (
                  <MenuItem key={statusOpt.value} value={statusOpt.value}>
                    {statusOpt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {fitnessId !== null ? (
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleUpdate}
                sx={{
                  bgcolor: "#E5622D",
                  "&:hover": { bgcolor: "#D0521B" },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  bgcolor: "#E5622D",
                  "&:hover": { bgcolor: "#D0521B" },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                ) : (
                  "Add"
                )}
              </Button>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClose}
              disabled={loading}
              sx={{ bgcolor: "#A9A9A9", "&:hover": { bgcolor: "#E1E1E1" } }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddFitness;
