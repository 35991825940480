import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getRequest, postRequest } from "../../axiosRoutes/index";
import SideNavBar from "../sidebar/SideNav";
import {
  Box,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import userPic from "../../images/User.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import { Mail } from "@mui/icons-material";

const EmployeeProfile = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { id, user } = location.state || {};
  const [medicalSum, setMedicalSum] = useState(0);
  const [fitnessSum, setFitnessSum] = useState(0);
  const [leaves, setLeaves] = useState([]);
  const [projects, setProjects] = useState([]);
  const [salaryData, setSalaryData] = useState({
    userId: "",
    currency: "",
    deduction: 0,
    netSalary: "",
    fullMonth: "",
    salaryMonth: "",
    tax: 0,
  });
  const [employee, setEmployee] = useState({
    empId: "",
    name: "",
    email: "",
    designation: "",
    phone: "",
    dob: "",
    address: "",
    joiningDate: "",
    joiningMonth: "",
    experience: "",
    salary: "",
  });

  useEffect(() => {
    const fetchEmployees = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest(`employee/${id}`, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        const birthDate = new Date(response.dob).toISOString().split("T")[0];
        const joining = new Date(response.joiningDate).toLocaleDateString(
          "en-US",
          {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }
        );
        const month = new Date(response.joiningDate).toLocaleDateString(
          "en-US",
          {
            month: "long",
            year: "numeric",
          }
        );
        const calculateExperience = (joiningDate) => {
          const startDate = new Date(joiningDate);
          const currentDate = new Date();
          let years = currentDate.getFullYear() - startDate.getFullYear();
          let months = currentDate.getMonth() - startDate.getMonth();
          if (
            months < 0 ||
            (months === 0 && currentDate.getDate() < startDate.getDate())
          ) {
            years--;
            months += 12;
          }
          if (years === 0) {
            return `${months} month${months !== 1 ? "s" : ""}`;
          }
          return `${years} year${years !== 1 ? "s" : ""} ${
            months > 0 ? `${months} month${months !== 1 ? "s" : ""}` : ""
          }`;
        };
        const experience = calculateExperience(response.joiningDate);
        setEmployee((prevData) => ({
          ...prevData,
          empId: response._id,
          name: response.name,
          email: response.email,
          designation: response.designation,
          phone: response.phone,
          dob: birthDate,
          address: response.address,
          joiningDate: joining,
          joiningMonth: month,
          salary: response.salary,
          experience: experience,
        }));
      } catch (error) {
        console.error("Error fetching employee:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [id]);

  useEffect(() => {
    const fetchSalary = async (id) => {
      try {
        const date = new Date();
        const mon = date.getMonth() + 1;
        const year = date.getFullYear();
        const month = `${mon}/${year}`;
        const access = sessionStorage.getItem("access");
        const res = await postRequest(
          `admin/salary/user/${id}`,
          {
            Authorization: `Bearer ${access}`,
          },
          { salaryMonth: month }
        );
        const [curMonth, curYear] = res.data.salaryMonth.split("/");
        const curDate = new Date(`${curYear}-${curMonth}-01`);
        const formattedMonth = curDate.toLocaleDateString("en-US", {
          month: "long",
          year: "numeric",
        });
        setSalaryData((prevData) => ({
          ...prevData,
          userId: res.data.userId,
          currency: res.data.currency,
          deduction: res.data.deduction,
          netSalary: res.data.netSalary,
          fullMonth: formattedMonth,
          salaryMonth: res.data.salaryMonth,
          tax: res.data.tax,
        }));
      } catch (error) {
        console.error("Error Fetching Salary", error);
      }
    };
    fetchSalary(id);
  }, [id]);

  useEffect(() => {
    const fetchFitness = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest(
          `admin/fitnessAllowance/user/${user}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        );
        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const currentYear = now.getFullYear();
        const sum = response
          .filter((fitness) => {
            const date = new Date(fitness.date);
            const fitnessMonth = date.getMonth() + 1;
            const fitnessYear = date.getFullYear();
            return (
              fitness.status === "approved" &&
              fitnessMonth === currentMonth &&
              fitnessYear === currentYear
            );
          })
          .reduce((total, fitness) => total + fitness.amountReimbursed, 0);

        setFitnessSum(sum);
      } catch (error) {
        console.error("Error fetching Fitness Allowances:", error);
      }
    };
    fetchFitness();
  }, [user]);

  useEffect(() => {
    const fetchProjects = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest(`project/user/${user}`, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        setProjects(response);
      } catch (error) {
        console.error("Error fetching Projects:", error);
      }
    };
    fetchProjects();
  }, [user]);

  useEffect(() => {
    const fetchMedicals = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest(
          `admin/medicalAllowance/user/${user}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        );
        const sum = response
          .filter((medical) => medical.status === "approved")
          .reduce((total, medical) => total + medical.amount, 0);
        setMedicalSum(sum);
      } catch (error) {
        console.error("Error fetching Medical Allowances:", error);
      }
    };
    fetchMedicals();
  }, [user]);

  useEffect(() => {
    const access = sessionStorage.getItem("access");
    const curYear = new Date().getFullYear();
    if (user !== null) {
      console.log("user id in leaves", user);
      const fetchLeaves = async (user) => {
        try {
          const res = await postRequest(
            `admin/leave/yearly/${user}`,
            {
              Authorization: `Bearer ${access}`,
            },
            { year: curYear }
          );
          console.log(res.data);
          setLeaves(res.data);
        } catch (error) {
          console.error("Error fetching Yearly Leave: ", error);
        }
      };
      fetchLeaves(user);
    }
  }, [user]);

  const handleMail = async (id, month) => {
    try {
      const access = sessionStorage.getItem("access");
      const res = await postRequest(
        `admin/salary/${id}`,
        {
          Authorization: `Bearer ${access}`,
        },
        { salaryMonth: month }
      );
      toast.success(res.data, toastConfig);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "1rem" }}>
        <Box sx={{ backgroundColor: "#000", padding: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div style={{ borderBottom: "4rem solid #FF5722" }}></div>
              <Card
                sx={{
                  backgroundColor: "#1a1a1a",
                  color: "#fff",
                  padding: 3,
                  paddingBottom: "5rem",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Avatar
                    src={userPic}
                    sx={{
                      width: 150,
                      height: 150,
                      margin: "auto",
                      border: "5px solid #fff",
                    }}
                  />
                  <Typography variant="h5" sx={{ marginTop: 2 }}>
                    <b>{employee.name}</b>
                  </Typography>
                  <Typography variant="subtitle1" mt={1}>
                    {employee.designation}
                  </Typography>
                  <Grid container direction="column" mt={2} spacing={1}>
                    <Grid item container alignItems="center">
                      <PhoneIcon
                        style={{
                          fontSize: 14,
                          backgroundColor: "#FF5722",
                          borderRadius: "50px",
                          padding: "4px",
                          marginRight: "0.5rem",
                        }}
                      />
                      <span style={{ fontSize: "14px" }}>{employee.phone}</span>
                    </Grid>
                    <Grid item container mt={1} alignItems="center">
                      <EmailIcon
                        style={{
                          fontSize: 14,
                          backgroundColor: "#FF5722",
                          borderRadius: "50px",
                          padding: "4px",
                          marginRight: "0.5rem",
                        }}
                      />
                      <span style={{ fontSize: "14px" }}>{employee.email}</span>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    margin: "2rem",
                    marginTop: "4rem",
                    textAlign: "center",
                    border: "2px solid #fff",
                    borderRadius: "10px",
                    padding: "3rem 0",
                  }}
                >
                  <Typography variant="h3">{leaves.total || 0}/20</Typography>
                  <Typography>Leave Approved</Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Card
                sx={{
                  backgroundColor: "#1a1a1a",
                  color: "#fff",
                  paddingLeft: 2,
                }}
              >
                <Box display="flex" alignItems="center" p="1rem">
                  <Typography variant="h4">Details</Typography>
                  <Box ml="auto" display="flex" gap="1rem">
                    {salaryData.userId !== "" ? (
                      <Button
                        variant="outlined"
                        startIcon={<Mail />}
                        onClick={() =>
                          handleMail(salaryData.userId, salaryData.salaryMonth)
                        }
                        sx={{
                          color: "#fff",
                          borderColor: "#fff",
                          "&:hover": {
                            variant: "contained",
                            backgroundColor: "#FF5722",
                            color: "white",
                            border: "none",
                          },
                        }}
                      >
                        Salary Slip
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderTop: "1px solid #FF5722",
                        borderRight: "1px solid #FF5722",
                        borderBottom: "1px solid #FF5722",
                        paddingBottom: "5rem",
                      }}
                    >
                      <Typography variant="h5" p="1rem">
                        Attendance Status
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Leave Status
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            12/15
                          </span>
                        </Box>
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Half Day Status
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            6/10
                          </span>
                        </Box>
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Work From Home Status
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            4/12
                          </span>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderTop: "1px solid #FF5722",
                        borderRight: "1px solid #FF5722",
                        borderBottom: "1px solid #FF5722",
                        paddingBottom: "4rem",
                      }}
                    >
                      <Typography variant="h5" p="1rem">
                        Designation
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Position
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            {employee.designation}
                          </span>
                        </Box>
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Experience
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            {employee.experience}
                          </span>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderTop: "1px solid #FF5722",
                        borderBottom: "1px solid #FF5722",
                        paddingBottom: "4rem",
                      }}
                    >
                      <Typography variant="h5" p="1rem">
                        Information
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Gross Salary
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            {employee.salary}
                          </span>
                        </Box>
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Date of Birth
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            {employee.dob}
                          </span>
                        </Box>
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          <span style={{ marginRight: "15px" }}>Address</span>
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "25px",
                              padding: "4px 20px",
                            }}
                          >
                            {employee.address}
                          </span>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderRight: "1px solid #FF5722",
                        borderBottom: "1px solid #FF5722",
                        paddingBottom: "4rem",
                      }}
                    >
                      <Typography variant="h5" p="1rem">
                        Allowances Status
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Medical Allowance
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            {medicalSum}/50000
                          </span>
                        </Box>
                      </Typography>
                      <Typography p="1rem">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "15px",
                          }}
                        >
                          Fitness Allowance
                          <span
                            style={{
                              background: "#000",
                              border: "1px solid #D0521B",
                              borderRadius: "50px",
                              padding: "4px 20px",
                            }}
                          >
                            {fitnessSum}
                          </span>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderRight: "1px solid #FF5722",
                        borderBottom: "1px solid #FF5722",
                      }}
                    >
                      <Typography variant="h5" p="1rem">
                        Projects
                      </Typography>
                      {projects && projects.length > 0 ? (
                        projects.map((project, index) => (
                          <Typography key={project.id} p="1rem">
                            <span style={{ color: "#FF5722" }}>
                              {index + 1}
                            </span>
                            <span style={{ marginLeft: "1rem" }}>
                              {project.title}
                            </span>
                          </Typography>
                        ))
                      ) : (
                        <Typography p="1rem">
                          Not Any Project Assigned Yet
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderBottom: "1px solid #FF5722",
                        paddingBottom: "4rem",
                      }}
                    >
                      <Typography variant="h5" p="1rem">
                        Office Inventory
                      </Typography>
                      <Typography p="1rem">
                        <span style={{ color: "#FF5722" }}>1</span>
                        <span style={{ marginLeft: "1rem" }}>Laptop</span>
                      </Typography>
                      <Typography p="1rem">
                        <span style={{ color: "#FF5722" }}>2</span>
                        <span style={{ marginLeft: "1rem" }}>LED Screen</span>
                      </Typography>
                      <Typography p="1rem">
                        <span style={{ color: "#FF5722" }}>3</span>
                        <span style={{ marginLeft: "1rem" }}>Mouse</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default EmployeeProfile;
