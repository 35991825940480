import React from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import Login from "./pages/login/Login";
import PageNotFound from "./pages/error/PageNotFound";
import AdminDashboard from "./pages/dashboard/AdminDashboard";
import Clients from "./pages/clients/Client";
import Employees from "./pages/employees/Employees";
import EmployeeProfile from "./pages/employees/EmployeeProfile";
import EmployeeMedical from "./pages/employees/EmployeeMedical";
import EmployeeFitness from "./pages/employees/fitness/EmployeeFitness";
import Invoices from "./pages/invoices/Invoices";
import Projects from "./pages/projects/Projects";
import Roles from "./pages/Roles/Roles";
import Inventory from "./pages/Inventory/Inventory";
import Salaries from "./pages/salaries/Salaries";
import Profile from "./pages/employee side/Employee/Profile";
import Fitness from "./pages/employee side/fitness/Fitness";
import Medical from "./pages/employee side/medical/Medical";
import Leaves from "./pages/employee side/leave/Leaves";
import AdminLeaves from "./pages/leaves/AdminLeaves";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <Protected Component={AdminDashboard} allowedRoles={["admin"]} />
        }
      />

      <Route
        path="/clients"
        element={<Protected Component={Clients} allowedRoles={["admin"]} />}
      />
      <Route
        path="/employees"
        element={<Protected Component={Employees} allowedRoles={["admin"]} />}
      />
      <Route
        path="/employee/profile"
        element={
          <Protected Component={EmployeeProfile} allowedRoles={["admin"]} />
        }
      />
      <Route
        path="/medical"
        element={
          <Protected Component={EmployeeMedical} allowedRoles={["admin"]} />
        }
      />
      <Route
        path="/fitness"
        element={
          <Protected Component={EmployeeFitness} allowedRoles={["admin"]} />
        }
      />
      <Route
        path="/invoices"
        element={<Protected Component={Invoices} allowedRoles={["admin"]} />}
      />
      <Route
        path="/projects"
        element={<Protected Component={Projects} allowedRoles={["admin"]} />}
      />
      <Route
        path="/roles"
        element={<Protected Component={Roles} allowedRoles={["admin"]} />}
      />
      <Route
        path="/inventory"
        element={<Protected Component={Inventory} allowedRoles={["admin"]} />}
      />
      <Route
        path="/leaves"
        element={<Protected Component={AdminLeaves} allowedRoles={["admin"]} />}
      />
      <Route
        path="/salaries"
        element={<Protected Component={Salaries} allowedRoles={["admin"]} />}
      />

      <Route
        path="/profile"
        element={<Protected Component={Profile} allowedRoles={["employee"]} />}
      />
      <Route
        path="/employee/fitness"
        element={<Protected Component={Fitness} allowedRoles={["employee"]} />}
      />
      <Route
        path="/employee/medical"
        element={<Protected Component={Medical} allowedRoles={["employee"]} />}
      />
      <Route
        path="/employee/leaves"
        element={<Protected Component={Leaves} allowedRoles={["employee"]} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
